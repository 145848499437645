import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, Container } from '@kogk/common'
import { DonationWidget, SplitHeading } from '@cmp/site'
import { TopHero, DonationForm, RichText } from '@cmp/sections'
import cn from 'classnames'
import qs from 'qs'
import styles from './DonationPage.module.scss'
import {
  formatSSN,
  formatPhone,
  formatCurrency,
  formatCardNumber,
} from '@src/utils'
import { useDonationStore } from '@src/stores'
import {
  DEFAULT_MONTHLY,
  DEFAULT_SINGLE,
  FALLBACK_AMOUNT,
} from '@src/utils/index'

const useDonationAmount = () => {
  const [amount, setAmount] = useState(0)
  const selectedAmount = useDonationStore((state) => state.selectedAmount)
  useEffect(() => {
    const params = qs.parse(window.location.search.slice(1))
    const amount = parseInt(params.amount, 10)
    setAmount(isNaN(amount) || amount <= 0 ? selectedAmount : amount)
  }, [selectedAmount])

  return amount || FALLBACK_AMOUNT
}

export default ({
  heroText,
  heroTextSuccess,
  successMessage,
  dim2,
  campaign,
  itemCode,
  monthly,
  zenterAudience,
  ...props
}) => {
  const [successData, setSuccessData] = useState()
  const amount = useDonationAmount()

  const amountFormatted = formatCurrency(amount)
  heroText = heroText.replace(/\$+/g, amountFormatted)
  const isBrowser = typeof window !== 'undefined'

  const params = isBrowser ? qs.parse(window.location.search.slice(1)) : []

  if (successData) {
    return (
      <Layout
        heroText={heroTextSuccess || heroText}
        introText={successMessage || 'Takk fyrir stuðninginn'}
        {...props}
      >
        <Summary amount={amount} monthly={monthly} {...successData} />
      </Layout>
    )
  }

  let amounts = monthly ? DEFAULT_MONTHLY : DEFAULT_SINGLE
  if (campaign == 'mumin') {
    amounts = [
      { amount: 4500 },
      { amount: 6500 },
      { amount: 8500 }
    ]
  }

  return (
    <Layout heroText={heroText} {...props}>
      {!Object.keys(params).includes('amount') ? (
        <DonationWidget
          amounts={amounts}
          standalone
        />
      ) : null}
      <DonationForm
        amount={amount}
        dim2={dim2}
        campaign={campaign}
        itemCode={itemCode}
        monthly={monthly}
        zenterAudience={zenterAudience}
        onSuccess={setSuccessData}
      />
    </Layout>
  )
}

const Layout = ({ heroText, introText, title, image, richText, children }) => {
  return (
    <Fragment>
      <TopHero imageArr={[]} heading={heroText} />
      <Container fluid>
        {Boolean(introText) && (
          <Row className="pt-5">
            <Col col={{ lg: 10 }} offset={{ lg: 1 }}>
              <p className="parag--large">{introText}</p>
            </Col>
          </Row>
        )}
        <Row className="pt-5 mb-2 mb-md-0 pb-4 pb-md-6">
          <Col col={{ lg: 4 }} offset={{ lg: 1 }}>
            {children}
          </Col>
          <Col col={{ lg: 5 }} offset={{ lg: 1 }}>
            <div className={styles.image}>
              <img alt=" " src={image} />
            </div>

            <SplitHeading
              tag="h3"
              classNames={cn(styles.heading, 'hdln--5 hdln--black-blue')}
            >
              {title}
            </SplitHeading>

            <article className="donation-page">
              <RichText html={richText} />
            </article>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

const Summary = ({ amount, monthly, name, ssn, phone, email }) => {
  return (
    <div>
      <p className="eyebrows eyebrows--black mb-3">Samantekt</p>
      <p>
        Styrkur: <b>{monthly ? 'Mánaðarlegur' : 'Eitt skipti'}</b>
        <br />
        Upphæð: <b>{formatCurrency(amount)} kr.</b>
      </p>
      <p>
        Nafn: <b>{name}</b>
        <br />
        Kennitala: <b>{formatSSN(ssn)}</b>
        <br />
        Farsími: <b>{formatPhone(phone)}</b>
        <br />
        Netfang: <b>{email}</b>
      </p>
    </div>
  )
}
