import {
  RichText,
  Hero,
  Embed,
  Feature,
  System,
  TopHero,
  BlueTextSection,
  ContentSectionGrey,
  ContactInfoSection,
  ThreeImageSection,
  LatestNewsSection,
  ImageGallery,
  FAQs,
  TextWithHeading,
  BannerImage,
  BlockQuote,
  DocumentSection,
  Uppeldishofn,
  SelectedStaffSection,
  TextwButton,
  FeaturedArticles,
  PostImage,
  Postlist,
} from '@cmp/sections'

import DonationForm from '../../components/templates/DonationPage/DonationPage.js'
import { mapNewsItem } from '../global-data'

export default {
  PrismicPostBodyUniImpNewsImage: {
    component: PostImage,
    dataToProps(data) {
      const {
        newsimage: image,
        caption: text,
      } = data.primary
      return { image, text }
    },
  },
  PrismicPostBodyUniImpNewsContent: {
    component: RichText,
    dataToProps(data) {
      const {
        text: { html },
      } = data.primary
      return { html }
    },
  },
  PrismicPageBodyFeaturedCategoryPost: {
    component: FeaturedArticles,
    dataToProps(data) {
      const {
        title: { text: title },
        first_two_cards_bigger: firstTwoBigger,
      } = data.primary
      const articles = data.items.map((i) => {
        const data = i.article.document.data

        const { url } = i.article

        return { data, url }
      })

      return { title, articles, firstTwoBigger }
    },
  },
  PrismicPageBodyNewsCategory: {
    component: LatestNewsSection,
    dataToProps(data) {
      const newsItems = data.posts.map((item) => {
        const {
          excerpt: { text: excerpt },
          date,
          feature_image: featureImage,
          page_title: { text: pageTitle },
        } = item.data

        const url = item.url
        const tag = item.tags[0]

        return { excerpt, date, featureImage, pageTitle, url, tag }
      })

      const {
        heading: { text: heading },
      } = data.primary

      return { heading, newsItems }
    },
  },
  PrismicPageBodyTextWithButtonLink: {
    component: TextwButton,
    dataToProps(data) {
      const {
        text: { html: text },
        button_text: { text: buttonText },
        button_link: { url: buttonLink },
      } = data.primary

      return { buttonLink, text, buttonText }
    },
  },
  PrismicPageBodyGreidsluForm: {
    component: DonationForm,
    dataToProps(data) {
      const {
        rich_text: { html: richText },
        title: { text: title },
        image: { url: image },
        hero_title: { text: heroText },
        hero_title_success: { text: heroTextSuccess },
        success_message: { text: successMessage },
        campaign,
        dim2,
        itemCode,
        zenterAudience,
        monthly
      } = data.primary

      return {
        richText,
        title,
        image,
        heroText,
        heroTextSuccess,
        successMessage,
        campaign: campaign ?? '',
        dim2: dim2 ?? '',
        itemCode: itemCode ?? '',
        zenterAudience: zenterAudience ?? '',
        monthly: monthly ?? false
      }
    },
  },
  PrismicPageBodyPostlistForm: {
    component: Postlist,
    dataToProps(data) {
      return {
        introText: data.primary.introText,
        zenterAudienceId: data.primary.zenterAudienceId,
        successMessage: data.primary.successMessage
      }
    },
  },
  PrismicPageBodyStaffSection: {
    component: SelectedStaffSection,
    dataToProps(data) {
      const staff = data.items.map((item) => {
        const {
          email: { text: email },
          image: { url: image },
          image_adult: { url: imageAdult },
          name: { text: name },
          phone1: { text: phone1 },
          phone2: { text: phone2 },
          title: { text: title },
        } = item

        return {
          email,
          image,
          imageAdult,
          phone1,
          phone2,
          title,
          name,
        }
      })

      return { staff }
    },
  },
  PrismicPageBodyUppeldishofn: {
    component: Uppeldishofn,
    dataToProps(data) {
      const {
        title: { text: heading },
        link: { url: link },
        link_title: { text: linkTitle },
      } = data.primary

      const uppeldishofn = data.items.map((item) => {
        const {
          excerpt: { text: excerpt },
          feature_image: featureImage,
          page_title: { text: pageTitle },
          date,
        } = item.uppeldishofn.document.data

        const link = item.uppeldishofn.url

        return {
          excerpt,
          featureImage,
          pageTitle,
          link,
          date,
        }
      })

      return { heading, link, linkTitle, uppeldishofn }
    },
  },
  PrismicPageBodyDocumentSection: {
    component: DocumentSection,
    dataToProps(data) {
      const {
        text: { text },
      } = data.primary

      const cards = data.items.map((item) => {
        const {
          image,
          excerpt: { text },
          title: { text: title },
          link: { url: link },
        } = item.document.document.data

        const linkTitle = item.document.document.data.link_title.text
          ? item.document.document.data.link_title.text
          : null

        return {
          image,
          text,
          title,
          link,
          linkTitle,
        }
      })

      return { text, cards }
    },
  },
  PrismicPostBodyBlockQuote: {
    component: BlockQuote,
    dataToProps(data) {
      const {
        text: { text },
        name: { text: name },
      } = data.primary
      return { text, name }
    },
  },
  PrismicPostBodyEmbed: {
    component: Embed,
    dataToProps(data) {
      return { html: data.primary.url.embed_url }
    },
  },
  PrismicPostBodyImage: {
    component: PostImage,
    dataToProps(data) {
      const {
        image_text: { text },
        image,
      } = data.primary
      return { image, text }
    },
  },
  PrismicPostBodyRichtext: {
    component: RichText,
    dataToProps(data) {
      const {
        heading: { text: heading },
        text: { html },
      } = data.primary
      return { heading, html }
    },
  },
  PrismicPageBodyImage: {
    component: BannerImage,
    dataToProps(data) {
      const {
        image: { url: image },
        image_text: { text },
      } = data.primary
      return { image, text }
    },
  },
  PrismicPageBodyTextWithH2: {
    component: TextWithHeading,
    dataToProps(data) {
      const {
        heading: { text: heading },
        text: { html: text },
      } = data.primary

      return { text, heading }
    },
  },
  PrismicPageBodyFaq: {
    component: FAQs,
    dataToProps(data) {
      const { items: faqs } = data

      const {
        heading: { text: heading },
      } = data.primary

      return { faqs, heading }
    },
  },
  PrismicPageBodyImageGallery: {
    component: ImageGallery,
    dataToProps(data) {
      const {
        heading: { text: heading },
      } = data.primary
      const { items: images } = data

      return { images, heading }
    },
  },
  PrismicPageBodyTextSectionBlue: {
    component: BlueTextSection,
    dataToProps(data) {
      const text = data.primary.text.text
      const sliceLabel = data.slice_label

      return { text, sliceLabel }
    },
  },
  PrismicPageBodyTopHeroCarousel: {
    component: TopHero,
    dataToProps(data) {
      const {
        heading: { text: heading },
      } = data.primary

      const hasCrumbs = data.primary.breadcrumb_name.text
      const breadcrumb = {
        name: data.primary.breadcrumb_name
          ? data.primary.breadcrumb_name.text
          : null,
        url: data.primary.breadcrumb_link
          ? data.primary.breadcrumb_link.url
          : null,
      }

      const imageArr = data.items.map((item) => {
        const {
          image: { url: image },
          heading: { text: heading },
          link: { url: link },
          link_name: { text: linkName },
          styrktar_verkefni: { document: styrktarVerkefni },
        } = item

        return {
          image,
          heading,
          link,
          linkName,
          styrktarVerkefni,
        }
      })

      return {
        imageArr,
        heading,
        breadcrumb,
        hasCrumbs,
      }
    },
  },
  PrismicPageBodyLatestNews: {
    component: LatestNewsSection,
    dataToProps(data) {
      const {
        heading: { text: heading },
        link: { url: link },
      } = data.primary

      const newsItems = data.items

      return { heading, link, newsItems }
    },
  },
  PrismicPageBodyThreeImageSection: {
    component: ThreeImageSection,
    dataToProps(data) {
      const {
        heading: { text: heading },
        link: { url: link },
        link_name: { text: linkName },
      } = data.primary

      const { items: postcards, slice_label: label } = data

      return { heading, postcards, label, link, linkName }
    },
  },
  PrismicPageBodyContactInfoSection: {
    component: ContactInfoSection,
    dataToProps(data) {
      const {
        heading: { text: heading },
      } = data.primary

      return { heading }
    },
  },
  PrismicPageBodyGreyContentSection: {
    component: ContentSectionGrey,
    dataToProps(data) {
      const {
        heading: { text: heading },
        link: { url: link },
        link_name: { text: linkName },
        text: { html: text },
      } = data.primary

      const { slice_label: label } = data

      return {
        heading,
        link,
        text,
        linkName,
        backgroundColor: label === 'white_background' ? 'white' : 'grey',
      }
    },
  },

  PrismicPageBodyRichText: {
    component: RichText,
    dataToProps(data) {
      const className = 'container'
      return {
        className,
        html: data.primary.rich_text.html,
      }
    },
  },

  PrismicPageBodyHeroImage: {
    component: Hero,
    dataToProps(data) {
      const {
        primary: {
          hero_caption: { html: caption },
          image,
        },
      } = data

      return {
        caption,
        image,
      }
    },
  },

  PrismicPageBodyFeature: {
    component: Feature,
    dataToProps(data) {
      const {
        primary: {
          text: { html },
          image,
          split_heading: { text: splitHeading },
        },
        slice_label: label,
      } = data

      return {
        imageAlignment: label === 'image_on_right' ? 'right' : 'left',
        html,
        image,
        splitHeading,
      }
    },
  },

  PrismicPageBodyEmbed: {
    component: Embed,
    dataToProps(data) {
      return { html: data.primary.url.html }
    },
  },

  PrismicPageBodySystem: {
    component: System,
    dataToProps(data) {
      return {
        programName: data.primary.program,
        posts: data.posts?.map(mapNewsItem),
        options: data.items.reduce((acc, item) => {
          acc[item.parameter] = item.value
          return acc
        }, {}),
      }
    },
  },
}
